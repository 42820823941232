.media-kit-container {
  text-align: center;
  background: #FFF;
  border-radius: 64px;
  font-color: #000000;
  padding: 72px 128px 72px 128px;
  text-align: center;
  margin: 24px;
}

.download-items-container {
  margin-top: 32px;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 20px; /* Adjust the gap between items */
}

.download-item {
  width: calc(33.333% - 20px); /* Adjust the width for three items per row */
  margin-bottom: 64px;
}

.download-image {
  width: 100%;
  height: 0;
  padding-bottom: 100%; /* Keeps the aspect ratio of 1:1 to maintain square shape */
  background-size: cover;
  background-position: center;
  border-radius: 48px;
  position: relative;
}

.download-image-content {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  /* Style content here */
}

/* Media query for screens smaller than 1024px */
@media (max-width: 1024px) {
  .download-item {
	width: 100%; /* Makes each item full width */
	margin-bottom: 32px; /* Adjust as needed */
  }
}







/* Media query for screens smaller than 768px */
@media (max-width: 768px) {
  
  .download-item {
  width: 100%; /* Makes each item full width */
  margin-bottom: 32px; /* Adjust as needed */
  }
  
  .download-items-container {
    margin-top: 64px;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    gap: 20px; /* Adjust the gap between items */
  }
  
  .media-kit-container {
  text-align: center;
  background: #FFF;
  border-radius: 24px;
  font-color: #000000;
  padding: 12px 24px;
  text-align: center;
  margin: 0;
}  
  
}

