/* src/fonts/fonts.css */
@font-face {
  font-family: 'BaristaBot Ginto Nord Bold';
  src: local('BaristaBot Ginto Nord Bold'),
	   url('/fonts/BaristaBot_Ginto_Nord/BaristaBotGintoNord-Bold.woff2') format('woff2'), /* Modern Browsers */
	   url('/fonts/BaristaBot_Ginto_Nord/BaristaBotGintoNord-Bold.woff') format('woff'); /* Older Browsers */
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'PX Grotesk Bold';
  src: local('PX Grotesk Bold'),
	   url('/fonts/Px-Grotesk/Px-Grotesk-Bold.woff2') format('woff2'), /* Modern Browsers */
	   url('/fonts/Px-Grotesk/Px-Grotesk-Bold.woff') format('woff'); /* Older Browsers */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Px Grotesk Light';
  src: local('Px Grotesk Light'),
	   url('/fonts/Px-Grotesk/Px-Grotesk-Light.woff2') format('woff2'), /* Modern Browsers */
	   url('/fonts/Px-Grotesk/Px-Grotesk-Light.woff') format('woff'); /* Older Browsers */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Px Grotesk Regular';
  src: local('Px Grotesk Regular'),
	   url('/fonts/Px-Grotesk/Px-Grotesk-Regular.woff2') format('woff2'), /* Modern Browsers */
	   url('/fonts/Px-Grotesk/Px-Grotesk-Regular.woff') format('woff'); /* Older Browsers */
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Px Grotesk Thin';
  src: local('Px Grotesk Mono Thin'),
	   url('/fonts/Px-Grotesk/Px-Grotesk-Mono-Thin.woff2') format('woff2'), /* Modern Browsers */
	   url('/fonts/Px-Grotesk/Px-Grotesk-Thin.woff') format('woff'); /* Older Browsers */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Px Grotesk Screen';
  src: local('Px Grotesk Screen'),
	   url('/fonts/Px-Grotesk/Px-Grotesk-Screen.woff2') format('woff2'), /* Modern Browsers */
	   url('/fonts/Px-Grotesk/Px-Grotesk-Screen.woff') format('woff'); /* Older Browsers */
  font-weight: normal;
  font-style: normal;
}



/* Add more @font-face rules for additional font weights or styles as needed */
