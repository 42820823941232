@import url(/fonts/BaristaBot_Ginto_Nord/BaristaBotGintoNord-Bold.woff2)format('woff2')
@import url(/fonts/BaristaBot_Ginto_Nord/BaristaBotGintoNord-Bold.woff)format('woff')
@import url(/fonts/Px-Grotesk/Px-Grotesk-Bold.woff2)format('woff2')
@import url(/fonts/Px-Grotesk/Px-Grotesk-Bold.woff)format('woff')
@import url(/fonts/Px-Grotesk/Px-Grotesk-Regular.woff2)format('woff2')
@import url(/fonts/Px-Grotesk/Px-Grotesk-Regular.woff)format('woff')
terbody {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Modern Browsers */ /* Older Browsers */ /* Modern Browsers */ /* Older Browsers */ /* Modern Browsers */ /* Older Browsers */
  
  body {
background-color: #E6E5E1;
}

/* Elements */

/* src/fonts/fonts.css */
@font-face {
  font-family: 'BaristaBot Ginto Nord Bold', sans-serif;
  src: local('BaristaBot Ginto Nord Bold'),
     url('/fonts/BaristaBot_Ginto_Nord/BaristaBotGintoNord-Bold.woff2') format('woff2'), /* Modern Browsers */
     url('/fonts/BaristaBot_Ginto_Nord/BaristaBotGintoNord-Bold.woff') format('woff'); /* Older Browsers */
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'PX Grotesk Bold', sans-serif;
  src: local('PX Grotesk Bold'),
     url('/fonts/Px-Grotesk/Px-Grotesk-Bold.woff2') format('woff2'), /* Modern Browsers */
     url('/fonts/Px-Grotesk/Px-Grotesk-Bold.woff') format('woff'); /* Older Browsers */
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'PX Grotesk Light', sans-serif;
  src: local('PX Grotesk Light'),
     url('/fonts/Px-Grotesk/Px-Grotesk-Light.woff2') format('woff2'), /* Modern Browsers */
     url('/fonts/Px-Grotesk/Px-Grotesk-Light.woff') format('woff'); /* Older Browsers */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'PX Grotesk Regular', sans-serif;
  src: local('PX Grotesk Regular'),
     url('/fonts/Px-Grotesk/Px-Grotesk-Regular.woff2') format('woff2'), /* Modern Browsers */
     url('/fonts/Px-Grotesk/Px-Grotesk-Regular.woff') format('woff'); /* Older Browsers */
  font-weight: 400;
  font-style: normal;
}





/* Add more @font-face rules for additional font weights or styles as needed */



.App-logo {
  height: 76px; /* Or any other dimensions */
  /* Other styles */
}

.button {
  font-family: 'BaristaBot Ginto Nord Bold', sans-serif;
  background-color: #E6E5E1;
  color: #000000; /* Black text */
  border: 3px solid #000000; /* Grey border */
  padding: 16px 32px; /* Adjust padding to match your button's size */
  font-size: 16px; /* Adjust font-size as needed */
  border-radius: 20px; /* Adjust border-radius for roundness of corners */
  cursor: pointer; /* Changes cursor to pointer on hover */
  text-align: center; /* Ensures text is centered */
  text-decoration: none; /* Removes underline from links if used on an 'a' element */
  display: inline-block; /* Allows setting padding and other box model properties */
  margin: 4px 2px; /* Spacing around the button */
  transition-duration: 0.4s; /* Smooth transition for hover effects */
  font-style: normal;
  font-weight: normal;
  }
  
  .button:hover {
    border: 3px solid #0D82FF; /* Grey border */
  }
  
  .tmrw-button {
  font-family: 'BaristaBot Ginto Nord Bold', sans-serif;
  background-color: #FFF;
  color: #000000; /* Black text */
  border: 3px solid #FF2A00; /* Grey border */
  padding: 16px 32px; /* Adjust padding to match your button's size */
  font-size: 16px; /* Adjust font-size as needed */
  border-radius: 20px; /* Adjust border-radius for roundness of corners */
  cursor: pointer; /* Changes cursor to pointer on hover */
  text-align: center; /* Ensures text is centered */
  text-decoration: none; /* Removes underline from links if used on an 'a' element */
  display: inline-block; /* Allows setting padding and other box model properties */
  margin: 4px 2px; /* Spacing around the button */
  transition-duration: 0.4s; /* Smooth transition for hover effects */
  font-style: normal;
  font-weight: normal;
  }
  
  .tmrw-button:hover {
    border: 3px solid #000 /* Grey border */
  }
  
  .mk-button {
  font-family: 'BaristaBot Ginto Nord Bold', sans-serif;
  background-color: #FFF;
  color: #000000; /* Black text */
  border: 3px solid #000000; /* Grey border */
  padding: 16px 32px; /* Adjust padding to match your button's size */
  font-size: 16px; /* Adjust font-size as needed */
  border-radius: 20px; /* Adjust border-radius for roundness of corners */
  cursor: pointer; /* Changes cursor to pointer on hover */
  text-align: center; /* Ensures text is centered */
  text-decoration: none; /* Removes underline from links if used on an 'a' element */
  display: inline-block; /* Allows setting padding and other box model properties */
  margin: 4px 2px; /* Spacing around the button */
  transition-duration: 0.4s; /* Smooth transition for hover effects */
  font-style: normal;
  font-weight: normal;
  }
  
  .mk-button:hover {
    border: 3px solid #0D82FF; /* Grey border */
  }
  
  .about-button {
    font-family: 'BaristaBot Ginto Nord Bold', sans-serif;
    box-shadow: 4px 4px 0px 0px rgba(0, 0, 0, 0.10);
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
    background-color: #EAFF00; /* The button color from your design */
    color: black;
    border: none;
    border-radius: 20px;
    padding: 16px 32px;
    margin-right: 24px;
    margin-bottom: 60px;
    cursor: pointer;
    /* Add hover effect if needed */
  }
  
  .char-button:hover {
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);
    background-color: #BBCC00;
  }
  
  .char-button {
    font-family: 'BaristaBot Ginto Nord Bold', sans-serif;
    box-shadow: 4px 4px 0px 0px rgba(0, 0, 0, 0.10);
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
    background-color: #EAFF00; /* The button color from your design */
    color: black;
    border: none;
    border-radius: 20px;
    padding: 16px 32px;
    margin-right: 24px;
    margin-bottom: 60px;
    cursor: pointer;
    /* Add hover effect if needed */
  }
  
  .char-button:hover {
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);
    background-color: #BBCC00;
  }
  
  
  .char-button-stacked {
    font-family: 'BaristaBot Ginto Nord Bold', sans-serif;
    box-shadow: 4px 4px 0px 0px rgba(0, 0, 0, 0.10);
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
    background-color: #EAFF00; /* The button color from your design */
    color: black;
    border: none;
    border-radius: 20px;
    padding: 16px 32px;
    margin-right: 24px;
    margin-bottom: 20px;
    cursor: pointer;
    /* Add hover effect if needed */
  }
  
 .char-button-stacked:hover {
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);
    background-color: #BBCC00;
  }
  
  .char-button-medium {
    font-family: 'BaristaBot Ginto Nord Bold', sans-serif;
    box-shadow: 2px 2px 0px 0px rgba(0, 0, 0, 0.10);
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
    background-color: #EAFF00; /* The button color from your design */
    color: black;
    border: none;
    border-radius: 16px;
    padding: 12px 24px;
    margin-right: 24px;
    margin-bottom: 60px;
    cursor: pointer;
    /* Add hover effect if needed */
  }
  
  .char-button-medium:hover {
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);
    background-color: #BBCC00
  }





.submit-button {
    font-family: 'BaristaBot Ginto Nord Bold', sans-serif;
    box-shadow: 4px 4px 0px 0px rgba(0, 0, 0, 0.10);
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
    background-color: #000; /* The button color from your design */
    color: white;
    border: none;
    text-decoration: none;
    border-radius: 20px;
    padding: 16px 32px;
    margin-right: 24px;
    margin-bottom: 60px;
    cursor: pointer;
    /* Add hover effect if needed */
  }
  
  .submit-button:hover {
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);
    background-color: #2E2E2D;
  }
  
  .social-button {
    font-family: 'BaristaBot Ginto Nord Bold', sans-serif;
    box-shadow: 4px 4px 0px 0px rgba(0, 0, 0, 0.10);
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
    text-decoration: none; /* Removes underline from links */
    display: inline-flex; /* Aligns text within the button */
    align-items: center;
    background-color: #EAFF00; /* The button color from your design */
    color: black;
    border: none;
    border-radius: 20px;
    padding: 16px 32px;
    margin-right: 24px;
    margin-bottom: 60px;
    cursor: pointer;
    /* Add hover effect if needed */
  }
  
  .social-button:hover {
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);
    background-color: #BBCC00
  } 
  
  .sf-social-button {
  font-family: 'BaristaBot Ginto Nord Bold', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  text-decoration: none; /* Removes underline from links */
  display: inline-flex; /* Aligns text within the button */
  align-items: center;
  background-color: #E6E5E1; /* The button color from your design */
  color: black;
  border: 3px solid var(--Black, #000);
  border-radius: 20px;
  padding: 16px 32px;
  margin-right: 24px;
  margin-bottom: 60px;
  cursor: pointer;
  }
  
  .sf-social-button:hover {
    border: 3px solid #0D82FF; /* Blue border */
  } 

  
  h1 {
    font-family: 'BaristaBot Ginto Nord Bold', sans-serif;
    font-size: 64px;
    font-style: normal;
    font-weight: 700;
    margin-bottom: 24px; 
  }
  
  
  h2, {
    font-family: 'PX Grotesk Regular', sans-serif;
  }
  
  h3 {
    color: var(--White, #FFF);
    font-family: 'BaristaBot Ginto Nord Bold', sans-serif;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  
  h4 {
    font-family: 'BaristaBot Ginto Nord Bold', sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    margin-bottom: 24px; 
  }
  
  
  p {
    font-family: 'PX Grotesk Regular', sans-serif;
    text-align: center;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 0px; 
  }
  
  
  
 /* Header & Navigation Section */ 
 
header {
  
   padding: 20px;
   display: flex;
   justify-content: space-between;
   align-items: center;
 }
 

.App-header {
  position: relative; /* This is essential */
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px;
}

.App-logo-container {
  flex: 1 1;
}

/* Hamburger icon */
.hamburger {
  display: none; /* Hidden by default */
  flex-direction: column;
  grid-gap: 7px;
  gap: 7px;
  cursor: pointer;
  border-radius: 12px;
  background: var(--White, #FFF);
  box-shadow: 2px 4px 12px rgba(0,0,0,0.1);
  padding: 16px;
}

.hamburger:hover {
  box-shadow: 0 0 0 0 ;
}

.hamburger div {
  width: 34px;
  height: 5px;
  background-color: black;
  border-radius: 10px;
}

.hamburger.active div:nth-child(1) {
  transform: rotate(45deg) translate(9.5px, 8px);
}

.hamburger.active div:nth-child(2) {
  opacity: 0;
}

.hamburger.active div:nth-child(3) {
  transform: rotate(-45deg) translate(9.5px, -7.5px);
}

.App-nav {
  flex: 2 1; /* Gives the navigation twice the space to allow centering */
  display: flex;
  justify-content: center;
  list-style: none; /* Removes default list styling */
  padding: 0; /* Resets padding */
}

.App-nav.show {
  display: block; /* Adjust based on your needs */
}

.App-nav a {
  text-decoration: none;
  cursor: pointer;
  color: black; /* Or any other color */
  margin-left: 32px; /* This adds spacing between the links */
  /* Add other styles such as padding, font-size, etc., as needed */
}



.App-signin-container {
  flex: 1 1;
  display: flex;
  justify-content: flex-end;
}

.nav-font {
  font-family: 'BaristaBot Ginto Nord Bold', sans-serif;
  font-size: 16px;
  padding: 14px 24px;
}

/* Additional styles for hover effects */
.App-nav a:hover {
  text-decoration: underline;
}

/* Default Section */

.default-section {
  background: #FFF;
  border-radius: 72px; /* This value determines how rounded the corners will be */
  font-color: #000000;
  padding: 72px 128px 72px 128px;
  text-align: center;
  margin: 24px;
}
.default-section img {
  width: 100%;
  padding-bottom: 32px;
}

.default-content {
padding-left: 128px;
padding-right: 128px;
}

.default-content H1 {
  padding-bottom: 48px;
}

.default-body {
  font-family: 'PX Grotesk Regular', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: left;
}

.about-section {
background: #FFF;
border-radius: 72px; /* This value determines how rounded the corners will be */
padding: 72px 128px 72px 128px;
margin: 24px;

}

.about-content {
padding-left: 168px;
padding-right: 168px;
text-align: center;
font-color: #000000;
}

.about-content p {
  font-family: 'PX Grotesk Regular', sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: center;
  align-items: center;
}




/* Slides Section */
.slides-section {
  width: 100%;
  overflow: hidden; /* Ensures nothing overflows the section boundaries */
}

.slides-content {
  padding: 10px;
  text-align: center;
  margin-top: 64px;
}

.slides {
 box-shadow: 0 5px 10px rgba(0,0,0,0.1);
 border-radius: 8px;
 display: block;
 margin-bottom: 1rem;
 padding-bottom: 0px !important;
}




/* Hero Section */

.hero {
  background: #E6E5E1;
  font-color: #000000;
  padding: 120px 128px;
  text-align: center;
}

.hero H1 {
  padding-left: 48px;
  padding-right: 48px;
}

.hero p {
  padding-left: 48px;
  padding-right: 48px;
}


/* Features Section */

.features-section {
  background-color: #000000; /* Dark black background */
  color: #EAFF00; /* White text */
  border-radius: 72px; /* This value determines how rounded the corners will be */
  padding: 60px 128px; /* Add padding to ensure the content doesn't touch the edges */
  margin: 24px;
}

.feature-image-container {
  /* Control the image container size and alignment */
  display: flex;
  align-items: flex-end; /* This will align the image to the bottom */
}

.feature-image {
  /* Adjust max-width as needed to control the image size */
  max-width: 71px;
  height: auto; /* Keep the image aspect ratio */
  padding-bottom: 30px;
}

.QR-image {
  /* Adjust max-width as needed to control the image size */
  width: 100%;
  max-width: 600px;
  height: auto; /* Keep the image aspect ratio */
  margin-bottom: 20px;
  border-radius: 32px;
}


.features h1 {
  
}

.features-header {
  color: #EAFF00;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.features-body {
  margin-top: 120px;
  margin-bottom: 60px;
  width: 100%;
  display: flex;
  flex-wrap: wrap; /* Allows items to wrap to the next line if not enough space */
  justify-content: space-around; /* Space items evenly */
  align-items: flex-start; /* Align items at the start of the cross axis */
  grid-gap: 48px;
  gap: 48px;
}


.features-body p {
  color: var(--Gray, #E6E5E1);
  font-size: 16px; 
  font-style: normal;
  font-weight: 400;
  line-height: 21px; /* 131.25% */
  text-align: left;
  
}

.features-about {
  margin-top: 120px;
  margin-bottom: 60px;
  width: 100%;
  display: flex;
  flex-wrap: wrap; /* Allows items to wrap to the next line if not enough space */
  justify-content: space-around; /* Space items evenly */
  align-items: flex-start; /* Align items at the start of the cross axis */
  grid-gap: 48px;
  gap: 48px;
}

.features-about p {
  color:  var(--Black, #000);
  font-size: 16px; 
  font-style: normal;
  font-weight: 400;
  line-height: 21px; /* 131.25% */
  text-align: left;
  
}

.feature-item1, .feature-item2, .feature-item3, .feature-item4 {
  flex-grow: 1; /* Allows items to grow and fill the available space */
  flex-basis: 0; /* Starts from 0 and grows as much as it can */
  padding: 64px;
  border-radius: 72px;
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  box-sizing: border-box;
}

.feature-item1 {
  border: 3px solid #EAFF00; /* Chartreuse border */
}

.feature-item2 {
  border: 3px solid #00BD7D; /* Green border */
}

.feature-item3 {
  border: 3px solid #FF2A00;
  color: #000;
}

.feature-item4 {
  border: 3px solid #000; /* Green border */
  color: #000
}



.header-feature-image, .body-feature-image {
  max-width: 100%;
  height: auto;
  margin-top: 20px; /* Space above image */
  
}

.feature-item1 h4 {
color: var(--Green, #EAFF00);
font-family: 'BaristaBot Ginto Nord Bold', sans-serif;
font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: normal;
text-transform: uppercase;
margin-bottom: 0.5em; /* Space below the h3 */
border-radius: 16px;
border: 4px solid var(--Chartreuse, #EAFF00);
padding: 8px;
justify-content: center;
}

.feature-item1 h3 {
 color: var(--Green, #EAFF00);
 font-family: 'BaristaBot Ginto Nord Bold', sans-serif;
 font-size: 32px;
 font-style: normal;
 font-weight: 700;
 line-height: normal;
 margin-bottom: 1em; /* Space below the paragraph */

}

.feature-item2 h4 {
  color: var(--Green, #00BD7D);
  font-family: 'BaristaBot Ginto Nord Bold', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  margin-bottom: 0.5em; /* Space below the h3 */
  border-radius: 16px;
  border: 4px solid var(--Green, #00BD7D);
  padding: 8px;
  justify-content: center;
}

.feature-item2 h3 {
  color: var(--Green, #00BD7D);
  font-family: 'BaristaBot Ginto Nord Bold', sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 1em; /* Space below the paragraph */
  
}

.feature-item3 h4 {
color: #FF2A00;
font-family: 'BaristaBot Ginto Nord Bold', sans-serif;
font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: normal;
text-transform: uppercase;
margin-bottom: 0.5em; /* Space below the h3 */
border-radius: 16px;
border: 4px solid #FF2A00;
padding: 8px;
justify-content: center;
}

.feature-item3 h3 {
 color: #FF2A00;
 font-family: 'BaristaBot Ginto Nord Bold', sans-serif;
 font-size: 32px;
 font-style: normal;
 font-weight: 700;
 line-height: normal;
 margin-bottom: 1em; /* Space below the paragraph */

}

.feature-item4 h4 {
  color:  var(--Black, #000);
  font-family: 'BaristaBot Ginto Nord Bold', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  margin-bottom: 0.5em; /* Space below the h3 */
  border-radius: 16px;
  border: 4px solid var(--Black, #000);
  padding: 8px;
  justify-content: center;
}

.feature-item4 h3 {
  color: var(--Black, #000);
  font-family: 'BaristaBot Ginto Nord Bold', sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 1em; /* Space below the paragraph */
  
}

/* Funding Section */

.funding {
  background-color: #0D82FF; /* Dark green background */
  color: #FFF; /* White text */
  border-radius: 72px; 
  padding: 60px 128px; 
  margin: 24px; 
  
}

.funding p {
  color: var(--White, #FFF);
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 36px; /* 150% */
  text-align: left;
  margin-bottom: 24px;
}
  .funding-image1 {
    height: 60px;
    margin: 24px;
    padding: 12px 0 12px 0;
  }
  
  .funding-image2 {
    height: 36px;
    margin: 24px;
    padding: 24px 0 24px 0;
  }
  
  .funding-image3 {
    height: 60px;
    margin: 24px;
    padding: 12px 0 12px 0;
  }
  
  .funding-image4 {
    height: 70px;
    margin: 24px;
    padding: 0 0 12px 0;
  }
  
  .partner-boxes {
    display: flex;
    justify-content: space-between;
    margin-bottom: 96px;
    margin-top: 20px;
  }
  
  .partner-box1 {
    background-color: #FFFF; /* Adjust color as needed */
    border: 6px solid var(--Green, #00BD7D);
    border-radius: 44px;
    padding: 20px;
    width: 20%; /* Adjust width based on your design */
    text-align: left;
  }
  
  .partner-box1 span {
    color: #00A06A;
    font-family: 'PX Grotesk Bold', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
    margin: 24px;
  }
  
  .partner-box1 h3 {
    font-size: 1.2rem;
    margin: 0.5rem 0;
  }
  
  .partner-box1 p {
    font-size: 0.9rem;
    color: #1E1E1E;
    font-family: 'PX Grotesk Bold', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  
  .partner-box2 {
    background-color: #042B56; /* Adjust color as needed */
    border-radius: 44px;
    padding: 20px;
    width: 20%; /* Adjust width based on your design */
    text-align: left;
  }
  
  .partner-box2 span {
    color: #00A06A;
    font-family: 'PX Grotesk Bold', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
    margin: 24px;
  }
  
  .partner-box2 h3 {
    font-size: 1.2rem;
    margin: 0.5rem 0;
  }
  
  .partner-box2 p {
   color: var(--White, #FFF);
   font-family: 'PX Grotesk Bold', sans-serif;
   font-size: 20px;
   font-style: normal;
   font-weight: 700;
   line-height: normal;
  }
  
  .funding-contact-container {
    display: flex;
    align-items: center;
    justify-content: flex-startstart;
    margin-top: 40px; /* Adjust as needed */
  }
  
  .funding-contact-container p {
    color: var(--White, #FFF);
    font-family: 'PX Grotesk Regular', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px; /* 140% */
  }
  
  
  .funding-image-container {
   flex: 0 1 auto; /* Adjust as needed, or use flex-basis with a fixed width */
    padding-right: 12px; /* Adjust as needed */
  }
  
  .funding-image {
    max-width: 120px;
    height: auto; /* Maintain aspect ratio */
    /* Add any additional styling for the image */
  }
  
  .funding-info-container {
    flex: 2 1 auto; /* Adjust as needed, or use flex-basis with a fixed width */
    padding-left: 12px; /* Adjust as needed */
    max-width: 540px;
    text-align: left; /* Align text to the left */
    margin-top: 10px;
  }
  
  .funding-button-container {
    flex: 1 1 auto; /* Adjust as needed, or use flex-basis with a fixed width */
    padding-left: 36px; /* Adjust as needed */
    text-align: left; /* Align text to the left */
    margin-top: 40px;
  }
  
  /* Team Section */
  
  
  .bento {
    width: 100%; /* Full width of its container to start */
    max-width: 1600px; /* Maximum width to ensure the image is not too large */
    padding: 20px 0; /* Adjust as needed */
  }
  
  .bento-image {
    width: 100%; /* Make the image responsive to the width of its container */
    height: auto; /* Maintain the aspect ratio of the image */
    border-radius: 28px; /* Optional: rounded corners for the image */
  }
  
  
  .team {
    background-color: #0D82FF; /* Dark green background */
    color: #FFF; /* White text */
    border-radius: 72px; 
    padding: 60px 128px; 
    margin: 24px; 
    
  }
  
  .team p {
    color: var(--White, #FFF);
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 36px; /* 150% */
    text-align: left;
    margin-bottom: 24px;
  }

    
    .team-contact-container {
      display: flex;
      align-items: center;
      justify-content: flex-startstart;
    }
    
    .team-contact-container p {
      color: var(--White, #FFF);
      font-family: 'PX Grotesk Regular', sans-serif;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 28px; /* 140% */
    }
    
    
    
    .team-info-container {
      flex: 2 1 auto; /* Adjust as needed, or use flex-basis with a fixed width */
      padding-left: 12px; /* Adjust as needed */
      max-width: 800px;
      text-align: left; /* Align text to the left */

    }
    
    .team-button-container {
      flex: 1 1 auto; /* Adjust as needed, or use flex-basis with a fixed width */
      padding-left: 36px; /* Adjust as needed */
      text-align: left; /* Align text to the left */
      margin-top: 40px;
    }



/* Contact Section*/
.contact-section {
  display: flex;
  justify-content: space-between;
  padding: 60px 128px; 
  background-color: #EAFF00; /* Replace with the correct color */
  border-radius: 72px; /* Adjust as needed */
  margin: 24px;
  text-align: left; 

}



.contact-content {
  flex: 2 1; /* Takes up 2/3rds of the space */
  padding-right: 20px; /* Ensures space between the text and the form */
  align-items: flex-start;
  
}

.contact-form {
  flex: 1 1; /* Takes up the other third */
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Aligns form items to the start (left) */
  margin-top: 40px;
}

.contact-form input[type="tel"] {
  margin-bottom: 10px; /* Space between input and small text */
  border-radius: 24px;
  border: 4px solid #000;
  display: flex;
  padding: 16px;
  grid-gap: 10px;
  gap: 10px;
  overflow: hidden;
  color: var(--Black, #000);
  text-overflow: ellipsis;
  font-family: 'BaristaBot Ginto Nord Bold', sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  max-width: 300px;
  background: var(--White, #FFF);
  /* Style your input field */
}

.contact-form .small-text {
  font-size: 10px;
  padding-left: 20px;
  margin-bottom: 16px; /* Space between small text and button */
  width: 100%;
  max-width: 300px;
}

.contact-form .submit-button {
  /* Style your sign up button */
}

  
.contact-section p {
  font-color: #000; /* White text */
  text-align: left;
  width: 80%;
  font-size: 24px;
}

.contact-section H1 {
  color: var(--Black, #000);
  font-family: 'BaristaBot Ginto Nord Bold', sans-serif;
  font-size: 64px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}



/* Footer Section */

.footer {
  text-align: left;
  padding: 120px 128px;
  background-color: #E6E5E1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}


.footer-copy {
  text-align: left;
}

.footer-copy a {
  text-decoration: none;
  color: #333;
  cursor: pointer;
}
  
  .footer-copy p {
  color: #333;
  font-family: 'PX Grotesk Regular', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  letter-spacing: -0.176px;
  text-align: left;
}

.footer-content {
  display: flex; /* Aligns logo and bubble side by side */
  align-items: center; /* Aligns items vertically in the center */
}

.footer-bot img {

  margin-right: 10px;
  width: 100%;
  max-width: 400px; /* Space between bot and text bubble if needed */
}

.footer-baristabot {
  margin: 64px 0 64px 0 ; /* Space above and below the button area */
}

.social-media-buttons {
  display: flex;
  cursor: pointer;
  justify-content: flex-start; /* Aligns buttons to the left */
  grid-gap: 20px;
  gap: 20px; /* Space between buttons */
  
}

/* No Footer Section */


.no-footer {
  background-color: #E6E5E1; /* Adjust with your actual color */
  padding: 120px 128px;
  align-content: center;
}

.no-footer-copy {
  text-align: center;
}

.no-footer-copy a {
  text-decoration: none;
  color: #333;
  cursor: pointer;
}

  .no-footer-copy p {
  color: #333;
  font-family: 'PX Grotesk Regular', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  letter-spacing: -0.176px;
}


/* Small Footer Section */


.small-footer {
  text-align: left; /* Aligns content to the left */
  background-color: #E6E5E1; /* Adjust with your actual color */
  display: flex; /* Establishes a flex container */
  flex-direction: column; /* Stacks children vertically */
  align-items: flex-start; /* Aligns children to the start of the cross-axis (left) */
  padding: 120px 128px;
}

.small-footer-content {
  display: flex; /* Aligns logo and bubble side by side */
  align-items: center; /* Aligns items vertically in the center */
}

.small-footer-bot img {
  width: 100%; /* Adjust the width of the bot image */
  max-width: 220px; 

}

.small-footer-baristabot {
  margin-top: 24px;
  margin-bottom: 24px;
  width: 300px;
}


.sf-social-media-buttons {
  display: flex;
  justify-content: flex-start; /* Aligns buttons to the left */
  grid-gap: 20px;
  gap: 20px; /* Space between buttons */
  margin-top: 24px;
  margin-bottom: 24px;
  
}

.small-footer-copy {
  text-align: left;
}
  
.small-footer-copy a {
  text-decoration: none;
  color: #333;
  cursor: pointer;
}

  .small-footer-copy p {
  color: #333;
  font-family: 'PX Grotesk Regular', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  letter-spacing: -0.176px;
  text-align: left;
}


  /* NOT FOUND */


.not-found-container {
  display: flex;
  padding: 72px 128px 0px 128px;
  flex-direction: column;
  align-items: center;
  grid-gap: 72px;
  gap: 72px;
  align-self: stretch;
 
  /* Add additional styling as needed */
}

.chat-bubbles-404 {
  align-items: center;
  min-height: 540px;
  grid-gap: 10px;
  gap: 10px;
  display: flex;
  flex-direction: column;
  width: 426px;
  padding: 24px; /* Add padding inside the chat container */
  position: relative;
  border-radius: 48px;
  background: var(--White, #FFF);
  box-shadow: 0px 32px 48px 0px rgba(0, 0, 0, 0.12), 0px 24px 24px -12px rgba(0, 0, 0, 0.05), 0px 12px 12px -6px rgba(0, 0, 0, 0.05), 0px 6px 6px -3px rgba(0, 0, 0, 0.05), 0px 3px 3px -1.5px rgba(0, 0, 0, 0.05), 0px 1px 1px -0.5px rgba(0, 0, 0, 0.05), 0px 0px 0px 1px rgba(0, 0, 0, 0.05);
  
  /* Add additional styling as needed */
}

.chat-bubbles {
  align-items: center;
  min-height: 600px;
  grid-gap: 10px;
  gap: 10px;
  display: flex;
  flex-direction: column;
  width: 426px;
  padding: 24px; /* Add padding inside the chat container */
  position: relative;
  border-radius: 48px;
  background: var(--White, #FFF);
  box-shadow: 0px 32px 48px 0px rgba(0, 0, 0, 0.12), 0px 24px 24px -12px rgba(0, 0, 0, 0.05), 0px 12px 12px -6px rgba(0, 0, 0, 0.05), 0px 6px 6px -3px rgba(0, 0, 0, 0.05), 0px 3px 3px -1.5px rgba(0, 0, 0, 0.05), 0px 1px 1px -0.5px rgba(0, 0, 0, 0.05), 0px 0px 0px 1px rgba(0, 0, 0, 0.05);
  
  /* Add additional styling as needed */
}


.bubble_r, .bubble_l, .go-home-link  {
  opacity: 0;
  border-radius: 24px;
  padding: 16px; /* Adjust padding as needed */
  margin-bottom: 16px; /* This adds a gap between bubbles */
  display: inline-flex; /* This will shrink the bubble to fit the content */
  max-width: 65%; /* Prevents bubbles from being too wide */
  
  
}

.bubble_r {
  background: var(--Blue, #0D82FF);
  color: var(--Neon-Yellow, #EAFF00);
  margin-left: auto; /* Pushes the bubble to the right */
  align-self: flex-end; /* Aligns the bubble to the right */
  font-family: 'PX Grotesk Regular', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 1.4; /* Adjusts the line height to ensure the bubble height fits the text */
  text-align: left;
}

.bubble_l {
  background: var(--Gray, #E6E5E1);
  align-self: flex-start; /* Aligns the bubble to the left */
  font-family: 'PX Grotesk Regular', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 1.4; /* Adjusts the line height to ensure the bubble height fits the text */
  text-align: left;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

#bubble1 {
  animation: fadeInUp 0.5s ease forwards 0.5s;
}

#bubble2 {
  animation: fadeInUp 0.5s ease forwards 1.5s;
}

#bubble3 {
  animation: fadeInUp 0.5s ease forwards 2.5s;
}

#bubble4 {
  animation: fadeInUp 0.5s ease forwards 3.5s;
}

#bubble5 {
  animation: fadeInUp 0.5s ease forwards 4.5s;
}
#bubble6 {
  animation: fadeInUp 0.5s ease forwards 5.5s;
}

/* Add this to your NotFound.css */

.go-home-link {
  background: var(--Black, #000000);
  color: var(--Neon-Yellow, #EAFF00);
  font-family: 'BaristaBot Ginto Nord Bold', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  cursor: pointer; /* Change mouse cursor on hover */
  align-self: flex-start; /* Aligns the bubble to the left */
}

.go-home-link a {
  text-decoration: none; /* Removes the underline from the link */
  color: var(--Black, #EAFF00);
}

.go-home-link:hover {
  background-color: #EAFF00; /* Slightly lighter color on hover */
  color: var(--Black, #000000);
}

.go-home-link:hover a {
  color: var(--Black, #000000);
}





.conversation-container {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center; /* Ensures children are centered horizontally */
  padding: 32px 64px;
}

.emoji-gallery {
  text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center; /* Ensures children are centered horizontally */
    padding: 32px 64px;
}

.emoji-selector {
    margin-bottom: 20px;
    display: flex;
    justify-content: center; /* Centers the emoji horizontally */
    grid-gap: 32px;
    gap: 32px; /* Adjusts spacing between emoji */
    flex-wrap: wrap; /* Allows emojis to wrap on smaller screens */
    padding-top: 64px; /* Space below emoji selector */
}



.emoji {
  color: var(--Black, #000);
  text-align: center;
  font-family: "BaristaBot Ginto Nord Bold";
  font-size: 64px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding: 12px 24px;
  border-radius: 24px;
  border: 2px solid var(--Gray, #E6E5E1);
  background: #FFF;
  cursor: pointer;
  -webkit-user-select: none;
          user-select: none; /* Prevents text selection */
}

.emoji.active {
  border-radius: 24px;
  border: 4px solid var(--Blue, #0D82FF);
  background: #FFF;
}

.chat-bubbles.transitioning {
    transition: opacity 0.3s ease-out;
}


@keyframes fadeIn {
    from { opacity: 0; transform: translateY(10px); }
    to { opacity: 1; transform: translateY(0); }
}

@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.chat-bubbles > div:nth-child(1) {
    animation: fadeInUp 0.5s ease forwards 0.5s;
}

.chat-bubbles > div:nth-child(2) {
    animation: fadeInUp 0.5s ease forwards 1.5s;
}

.chat-bubbles > div:nth-child(3) {
    animation: fadeInUp 0.5s ease forwards 2.5s;
}

.chat-bubbles > div:nth-child(4) {
    animation: fadeInUp 0.5s ease forwards 3.5s;
}

.chat-bubbles > div:nth-child(5) {
    animation: fadeInUp 0.5s ease forwards 4.5s;
}

.chat-bubbles > div:nth-child(6) {
    animation: fadeInUp 0.5s ease forwards 5.5s;
}


.component-container {
  /* Adjust this container to fit the design */
  display: flex;
  padding: 72px 128px 0px 128px;
  flex-direction: column;
  align-items: center;
  grid-gap: 72px;
  gap: 72px;
  align-self: stretch;
}

.image-container {
  /* Adjust this container to fit the design */
  margin-top: 64px;
  width: 100%;
  padding: 24px;
}

.main-image {
    width: 100%;
    max-width: 426px;
    height: auto;
}



@media (max-width: 1375px) {
	
	
	  .partner-boxes {
			flex-direction: column;
			align-items: center;
		  }
		
		.partner-box1, .partner-box2 {
			width: 75%; /* Adjust based on your preference */
			margin-bottom: 32px; /* Add some space between the boxes when they stack */
		  }


	}






@media (max-width: 1190px) {
	  
	  
	  
	  .footer-title {
		font-size: 400px; /* Adjust for smaller screens */
	  }
	
.App-nav {
	display: none; /* Hide regular nav */
	
  }

.App-header.nav-open .nav-font {
	padding: 0;
}

	/* Show hamburger icon */
  .hamburger {
	display: flex;
	order: 2; /* Ensure it's positioned at the end */
  }
  
  .App-signin-container {
	  display: none; /* Hide the sign-up button */
	}
  
	.App-header.nav-open .App-nav {
	display: flex;
	flex-direction: column;
	position: absolute;
	top: 80%;
	right: 0;
	align-items: right;
	height: 220px;
	margin-right: 20px;
	z-index: 100; /* Ensures the menu is above other content */
	overflow-y: auto; /* Allows scrolling within the menu */		width: 200px;
	background-color: #EAFF00;
	border-radius: 24px;
	}
	
	.App-header.nav-open .App-nav a {
	  display: block; /* Makes each link full-width */
	  padding: 10px 20px; /* Adds space around the text for easier tap targets */
	
	  color: #333; /* Text color */
	  text-align: right; /* Centers the link text */
	}  
	
	.default-section, .slides-content, .default-content, .about-content {
		padding: 12px 24px; /* Reduce padding for smaller screens */
	  }
	  
	  .partner-boxes {
		  flex-direction: column;
		  align-items: center;
		}
	  
	  .partner-box1, .partner-box2 {
		  width: 75%; /* Adjust based on your preference */
		  margin-bottom: 32px; /* Add some space between the boxes when they stack */
		}
		
		.contact-section {
		  flex-direction: column;
		}
		.contact-content p {
			margin-bottom: 24px;
		}
		.contact-content h1 {
		   font-size: 56px;
		   font-style: normal;
		   font-weight: 700;
		   margin-bottom: 24px; 
		}
		
		.contact-form {
		  width: 100%; /* Ensure the contact form uses full width */
		  max-width: 640px;
		  margin-top: 24px;
		}
		
		.contact-form input[type="tel"] {
			width: 100%;
			max-width: 340px;
		}
		
	.contact-form .small-text {
		font-size: 10px;
		padding-left: 20px;
		margin-bottom: 16px;
		width: 100%;
		max-width: 340px;
	}

	  
	  .footer {
		  padding: 64px 64px; /* Reduce padding */
		}
		
		.small-footer {
			padding-left: 32px 32px; /* Adjust padding as needed */
		  }
		
		.footer-baristabot {
			width: 100%; /* Scale down to 50% of its container width */
			max-width: 620px; /* Optional: ensure it doesn't grow larger than its original size */
			margin-top: 32px;
			
		  }
		  
		  .small-footer-baristabot {
			  width: 100%; /* Scale down to 50% of its container width */
			  max-width: 420px; /* Optional: ensure it doesn't grow larger than its original size */
			  margin-top: 32px;
			  padding-left: 32px; /* Adjust padding as needed */
			}
		 
		  .footer-bot img {
			width: 100%; /* Adjust the width of the bot image */
			max-width: 420px; 
		  }

		  .small-footer-bot img {
			width: 100%; /* Adjust the width of the bot image */
			max-width: 220px; 
		  
		  }
		
		  .social-media-buttons {
			grid-gap: 10px;
			gap: 10px; /* Adjust the gap between buttons */
			margin-bottom: 32px
		  }
		  
		  .sf-social-media-buttons {
			grid-gap: 10px;
			gap: 10px; /* Adjust the gap between buttons */
			padding-left: 32px; 
		  }
		  
		  .footer-copy p {
				padding-left: 32px;
			}
			
			.small-footer-copy p {
			  padding-left: 32px;
		  }
		  

		  .emoji-gallery {
			text-align: center;
		  }
		  
		  .emoji-selector {
			margin-bottom: 20px; /* Space below emoji selector */
		  }
		  
		  .emoji {
			color: var(--Black, #000);
			text-align: center;
			font-family: 'BaristaBot Ginto Nord Bold', sans-serif;
			font-size: 54px;
			font-style: normal;
			font-weight: 700;
			line-height: normal;
			padding: 12px 24px;
			grid-gap: 10px;
			gap: 10px;
			border-radius: 18px;
			border: 2px solid var(--Gray, #E6E5E1);
			background: #FFF;
			grid-gap: 16px;
			gap: 16px;
		  }
		  
		  .emoji.active {
			  grid-gap: 10px;
			  gap: 10px;
			  border-radius: 18px;
			  border: 4px solid var(--Blue, #0D82FF);
			  background: #FFF;
		  }
		  
		  
		  .image-container {
			/* Adjust this container to fit the design */
			margin: auto;
			margin-top: 64px;
			width: 100%;
			max-width: 426px;
			padding: 0;
		  }
		  
		  .conversation-container {
			  /* Adjust this container to fit the design */
			  margin: auto;
			  margin-top: 64px;
			  width: 100%;
			  max-width: 426px;
			  padding: 0;
			}
		  
		  .main-image {
			max-width: 100%;
			height: auto; /* Keeps the aspect ratio of the image */
			/* Further styling as needed */
		  }
		  
		 
			
			h1 {
				font-size: 54px;
				margin-bottom: 24px; 
			  }
			  
			  p {
				  font-size: 26px;
				  margin-bottom: 64px; 
				}
		  
		  
	  }


		




@media (max-width: 768px) { /* Adjust breakpoint as needed */
  .main-image {
	  width: 100%;
		padding: 0;
		margin: 0; 
  }
  .image-container {
	  /* Adjust this container to fit the design */
	  margin: 0;
	  margin-top: 24px;
	  width: 100%;
	  max-width: 500px;
	  padding: 0;
	}
	.conversation-container {
		padding: 0;
		margin: 0;
	}
   
   .emoji-gallery {
	 text-align: center;
	 padding: 0;
	 margin-top: 24px;
   }
   
   
   .emoji-selector {
		margin-bottom: 20px;
		padding-top: 0; /* Space below emoji selector */
		grid-gap: 10px;
		gap: 10px;
	  }
	  
   .emoji {
	   border-radius: 18px;
	   padding: 10px 16px;
	   font-size: 36px;
   }
   
   .emoji-selector {
	 margin-bottom: 20px;
	 padding-top: 0; /* Space below emoji selector */
	 grid-gap: 10px;
	 gap: 10px;
   }
   
   .bubble_r, .bubble_l {
	 opacity: 0;
	 text-decoration: none !important;
	 font-size: 16px;
	 line-height: 1.2; /* Adjusts the line height to ensure the bubble height fits the text */
	 border-radius: 18px;
	 padding: 12px;
	 margin-bottom: 8px;
   }
   
   .go-home-link {
	   font-size: 14px;
	   text-decoration: none !important;
	   border-radius: 18px;
	   padding: 14px;
	   margin-bottom: 8px;
   }
   .not-found-container {
	   margin: 0;
	   padding: 0;
   }
   
   .not-found-container H1 {
		  margin: 0;
		  padding: 0;
	  }
	  
	  .not-found-container p {
		  padding-right: 12px;
		  padding-left: 12px;
	  }
  
   .chat-bubbles-404 {
	 min-height: 400px;
	 width: 80%;

   }
   
   .chat-bubbles {
		min-height: 540px;
		width: 90%;
  		margin: 24px 0 0;
		 border-radius: 28px;
	  }


  .hero {
		margin: 0;
		padding: 0;
	}
	
	.hero H1 {
		padding-left: 12px;
		padding-right: 12px;
	}
	
	.hero p {
		padding-right: 12px;
		padding-left: 12px;
	}
  
  .default-section, .about-section, .features-section {
	  margin: 0;
	  border-radius: 64px;
  }
  
  .about-content p {
	font-size: 16px;
	line-height: 24px; /* 150% */
  }
  

  .feature-item1, .feature-item2 {
	flex-basis: 100%; /* Each item takes full width on smaller screens */
	padding: 32px; /* Optionally adjust padding for smaller screens */
	margin: 10px 0; /* Adjust margin as needed */
  }
  
  .features-header {
	  flex-direction: column; /* Stack items vertically on smaller screens */
	  padding: 30px 20px; /* Adjust padding for smaller screens */
	}
	
	.par {
		flex-direction: column; /* Stack image and info on top of each other on smaller screens */
		  
	}
	
	.funding p {
	  color: var(--White, #FFF);
	  font-family: 'PX Grotesk Regular', sans-serif;
	  font-size: 16px;
	  font-style: normal;
	  font-weight: 400;
	  line-height: 24px; /* 150% */
	  text-align: left;
	  margin-bottom: 20px;
	}
	
	.funding {
		padding: 30px 32px; /* Adjust padding for smaller screens */
		margin: 0;
		margin-top: 24px;
		border-radius: 64px;
	}
	
	.funding-contact-container {
		flex-direction: column; /* Stack image and info on top of each other on smaller screens */
	  }
	
	  .funding-image-container, .funding-info-container {
		flex-basis: 100%; /* Full width on smaller screens */
		padding: 10px 0; /* Adjust as needed */
	  }
	 
	  
	.partner-box1, .partner-box2 {
		width: 80%; /* Boxes take up full container width */
	  }
		

	  
	.team {
		  padding: 30px 32px; /* Adjust padding for smaller screens */
		  margin: 0;
		  margin-top: 24px;
		  border-radius: 64px;
		 
	  }
	  
	  .team p {
		  color: var(--White, #FFF);
		  font-family: 'PX Grotesk Regular', sans-serif;
		  font-size: 16px;
		  font-style: normal;
		  font-weight: 400;
		  line-height: 24px; /* 150% */
		  text-align: left;
		  margin-bottom: 12px;
		}
		
		.team H3 {
			color: var(--White, #FFF);
			font-family: 'BaristaBot Ginto Nord Bold', sans-serif;
			font-size: 24px;
			font-style: normal;
			font-weight: 700;
			line-height: normal;
		}
	  
	  .team-contact-container {
		  flex-direction: column; /* Stack image and info on top of each other on smaller screens */
		}
	  
		.team-image-container, .team-info-container {
		  flex-basis: 100%; /* Full width on smaller screens */
		  padding: 20px 0; /* Adjust as needed */
		  
		}

		.bento {
			margin: 16px auto; /* Less margin on smaller screens */
		  }
		
		  .team-button-container {
			justify-content: center; /* Center buttons when they wrap */
		  }


		.hero, .features-section, .default-section, .about-section, .contact-section, .team{
			padding: 30px 24px; /* Further reduce padding for smaller devices */
		  }
		  
		
		  .feature-item1, .feature-item2 {
			flex-basis: 100%; /* Make feature items stack vertically */
		  }
		
		  .contact-section {
			flex-direction: column;
			margin: 0;
			margin-top: 24px;
			border-radius: 64px;
		  }
		  .contact-content p {
			  margin-bottom: 24px;
			  width: 100%;
				font-size: 16px;
		  }
		  .contact-content h1 {
			font-family: 'BaristaBot Ginto Nord Bold', sans-serif;
			 font-size: 32px;
			 font-style: normal;
			 font-weight: 700;
			 margin-bottom: 24px; 
		  }
		  
		  .contact-form {
			width: 100%; /* Ensure the contact form uses full width */
			max-width: 320px;
			margin-top: 24px;
		  }
		  
		  .contact-form input[type="tel"] {
			  width: 100%;
			  max-width: 280px;
		  }
		  
			  
		  .contact-form .small-text {
			  font-size: 10px;
			  padding-left: 20px;
			  margin-bottom: 16px;
			  width: 100%;
			  max-width: 290px;
		  }
		  
		  h1 {
			  font-family: 'BaristaBot Ginto Nord Bold', sans-serif;
			  font-size: 32px;
			  font-style: normal;
			  font-weight: 700;
			  margin-bottom: 24px; 
			}
			
			p {
				font-family: 'PX Grotesk Regular', sans-serif;
				text-align: center;
				font-size: 16px;
				font-style: normal;
				font-weight: 400;
				line-height: normal;
				margin-bottom: 32px; 
			  }
			  
				  
				  .footer {
					padding: 64px 12px; /* Reduce padding */
				  }
				  
				  .small-footer {
					padding: 64px 12px; /* Reduce padding */
				  }
				  
				  .no-footer {
					  padding: 64px 12px; /* Reduce padding */
					}
					
				  .footer-baristabot {
					  width: 100%; /* Scale down to 50% of its container width */
					  max-width: 300px; /* Optional: ensure it doesn't grow larger than its original size */
					  margin-top: 32px;
					  margin-bottom: 32px;
					  padding: 0;
					}
					
					.small-footer-baristabot {
					  width: 100%; /* Scale down to 50% of its container width */
					  max-width: 300px; /* Optional: ensure it doesn't grow larger than its original size */
					  margin-top: 32px;
					  margin-bottom: 32px;
					  padding: 0;
					}
				   
					.footer-bot img {
					  width: 100%; /* Adjust the width of the bot image */
					  max-width: 220px; 
					  padding: 0;
					}
					
					.small-footer-bot img {
						width: 100%; /* Adjust the width of the bot image */
						max-width: 220px; 
						padding: 0;
					  }
					
					.footer-copy p {
						  padding-left: 0;
						  font-size: 12px;
						  padding: 0;
					  }
					  
					.small-footer-copy p {
					  padding-left: 0;
					  font-size: 12px;
					  padding: 0;
					  
						}
						
						.no-footer-copy p {
						  padding-left: 0;
						  font-size: 12px;
						  padding: 0;
						  
							}

					.social-media-buttons {
					 flex-direction: column; /* Stack image and info on top of each other on smaller screens */
					  grid-gap: 10px;
					  gap: 10px; /* Adjust the gap between buttons */
					  padding: 0;
					}
					
					.sf-social-media-buttons {
						flex-direction: column; /* Stack image and info on top of each other on smaller screens */
						grid-gap: 10px;
						gap: 10px; /* Adjust the gap between buttons */
						padding: 0;
					  }
					  
					  .mk-button, .char-button-stacked, .char-button, .social-button, .sf-social-button, .submit-button {
						  padding: 14px 28px; /* Adjust padding for smaller buttons */
							  font-size: 14px;
							  margin-bottom: 20px;/* Adjust font size for smaller text */
						
					  }
					  
					  .mk-button,.sf-social-button {
							padding: 12px 24px; /* Adjust padding for smaller buttons */
								font-size: 14px;
								margin-bottom: 10px;/* Adjust font size for smaller text */
						  
						}
					
				
}
	  
		  

		
	


/* src/fonts/fonts.css */
@font-face {
  font-family: 'BaristaBot Ginto Nord Bold';
  src: local('BaristaBot Ginto Nord Bold'),
	   url('/fonts/BaristaBot_Ginto_Nord/BaristaBotGintoNord-Bold.woff2') format('woff2'), /* Modern Browsers */
	   url('/fonts/BaristaBot_Ginto_Nord/BaristaBotGintoNord-Bold.woff') format('woff'); /* Older Browsers */
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'PX Grotesk Bold';
  src: local('PX Grotesk Bold'),
	   url('/fonts/Px-Grotesk/Px-Grotesk-Bold.woff2') format('woff2'), /* Modern Browsers */
	   url('/fonts/Px-Grotesk/Px-Grotesk-Bold.woff') format('woff'); /* Older Browsers */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Px Grotesk Light';
  src: local('Px Grotesk Light'),
	   url('/fonts/Px-Grotesk/Px-Grotesk-Light.woff2') format('woff2'), /* Modern Browsers */
	   url('/fonts/Px-Grotesk/Px-Grotesk-Light.woff') format('woff'); /* Older Browsers */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Px Grotesk Regular';
  src: local('Px Grotesk Regular'),
	   url('/fonts/Px-Grotesk/Px-Grotesk-Regular.woff2') format('woff2'), /* Modern Browsers */
	   url('/fonts/Px-Grotesk/Px-Grotesk-Regular.woff') format('woff'); /* Older Browsers */
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Px Grotesk Thin';
  src: local('Px Grotesk Mono Thin'),
	   url('/fonts/Px-Grotesk/Px-Grotesk-Mono-Thin.woff2') format('woff2'), /* Modern Browsers */
	   url('/fonts/Px-Grotesk/Px-Grotesk-Thin.woff') format('woff'); /* Older Browsers */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Px Grotesk Screen';
  src: local('Px Grotesk Screen'),
	   url('/fonts/Px-Grotesk/Px-Grotesk-Screen.woff2') format('woff2'), /* Modern Browsers */
	   url('/fonts/Px-Grotesk/Px-Grotesk-Screen.woff') format('woff'); /* Older Browsers */
  font-weight: normal;
  font-style: normal;
}



/* Add more @font-face rules for additional font weights or styles as needed */

.media-kit-container {
  text-align: center;
  background: #FFF;
  border-radius: 64px;
  font-color: #000000;
  padding: 72px 128px 72px 128px;
  text-align: center;
  margin: 24px;
}

.download-items-container {
  margin-top: 32px;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  grid-gap: 20px;
  gap: 20px; /* Adjust the gap between items */
}

.download-item {
  width: calc(33.333% - 20px); /* Adjust the width for three items per row */
  margin-bottom: 64px;
}

.download-image {
  width: 100%;
  height: 0;
  padding-bottom: 100%; /* Keeps the aspect ratio of 1:1 to maintain square shape */
  background-size: cover;
  background-position: center;
  border-radius: 48px;
  position: relative;
}

.download-image-content {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  /* Style content here */
}

/* Media query for screens smaller than 1024px */
@media (max-width: 1024px) {
  .download-item {
	width: 100%; /* Makes each item full width */
	margin-bottom: 32px; /* Adjust as needed */
  }
}







/* Media query for screens smaller than 768px */
@media (max-width: 768px) {
  
  .download-item {
  width: 100%; /* Makes each item full width */
  margin-bottom: 32px; /* Adjust as needed */
  }
  
  .download-items-container {
    margin-top: 64px;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    grid-gap: 20px;
    gap: 20px; /* Adjust the gap between items */
  }
  
  .media-kit-container {
  text-align: center;
  background: #FFF;
  border-radius: 24px;
  font-color: #000000;
  padding: 12px 24px;
  text-align: center;
  margin: 0;
}  
  
}


