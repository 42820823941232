

.conversation-container {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center; /* Ensures children are centered horizontally */
  padding: 32px 64px;
}

.emoji-gallery {
  text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center; /* Ensures children are centered horizontally */
    padding: 32px 64px;
}

.emoji-selector {
    margin-bottom: 20px;
    display: flex;
    justify-content: center; /* Centers the emoji horizontally */
    gap: 32px; /* Adjusts spacing between emoji */
    flex-wrap: wrap; /* Allows emojis to wrap on smaller screens */
    padding-top: 64px; /* Space below emoji selector */
}



.emoji {
  color: var(--Black, #000);
  text-align: center;
  font-family: "BaristaBot Ginto Nord Bold";
  font-size: 64px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding: 12px 24px;
  border-radius: 24px;
  border: 2px solid var(--Gray, #E6E5E1);
  background: #FFF;
  cursor: pointer;
  user-select: none; /* Prevents text selection */
}

.emoji.active {
  border-radius: 24px;
  border: 4px solid var(--Blue, #0D82FF);
  background: #FFF;
}

.chat-bubbles.transitioning {
    transition: opacity 0.3s ease-out;
}


@keyframes fadeIn {
    from { opacity: 0; transform: translateY(10px); }
    to { opacity: 1; transform: translateY(0); }
}

@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.chat-bubbles > div:nth-child(1) {
    animation: fadeInUp 0.5s ease forwards 0.5s;
}

.chat-bubbles > div:nth-child(2) {
    animation: fadeInUp 0.5s ease forwards 1.5s;
}

.chat-bubbles > div:nth-child(3) {
    animation: fadeInUp 0.5s ease forwards 2.5s;
}

.chat-bubbles > div:nth-child(4) {
    animation: fadeInUp 0.5s ease forwards 3.5s;
}

.chat-bubbles > div:nth-child(5) {
    animation: fadeInUp 0.5s ease forwards 4.5s;
}

.chat-bubbles > div:nth-child(6) {
    animation: fadeInUp 0.5s ease forwards 5.5s;
}


.component-container {
  /* Adjust this container to fit the design */
  display: flex;
  padding: 72px 128px 0px 128px;
  flex-direction: column;
  align-items: center;
  gap: 72px;
  align-self: stretch;
}

.image-container {
  /* Adjust this container to fit the design */
  margin-top: 64px;
  width: 100%;
  padding: 24px;
}

.main-image {
    width: 100%;
    max-width: 426px;
    height: auto;
}


